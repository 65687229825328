@keyframes glow {
  0% {
    /*box-shadow: .25em .5em 3.5em rgba(255,255,255,0.7)*/
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7), .25em .5em 3.5em rgba(255, 255, 255, 0.7);
  }

  70% {
    box-shadow: 0 0 0 1em rgba(255, 255, 255, 0), 0 0 0 rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0), .25em .5em 3.5em rgba(255, 255, 255, 0.7);
  }
}

.navigation-links3-nav {
  flex: 0 0 auto;
  display: flex;
  font-size: 1rem;
  align-items: center;
  padding-left: 4rem;
  padding-right: 4rem;
  flex-direction: row;
  animation-fill-mode: transparent;
}

.navigation-links3-navlink {
  color: var(--linkcolor) !important;
  text-decoration: none;
}

.navigation-links3-thq-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}

.navigation-links3-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  visibility: visible;
  opacity: 1;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: .8rem;
  border-radius: var(--dl-radius-radius-radius2);
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}

.navigation-links3-text {
  color: var(--basecolor) !important;
  transition: color 1180ms ease-in-out;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  margin-left: var(--dl-space-space-twounits);
  vertical-align: middle;
}

.navigation-links3-dropdown-arrow {
  transition: 0.3s;
}

.navigation-links3-icon {
  width: 2.25rem;
  height: 2.25rem;
  margin-top: auto;
  transition: 0.3s;
  margin-bottom: auto;
  fill: var(--basecolor) !important;
}

.navigation-links3-dropdown-list {
  left: 0%;
  width: max-content;
  display: inline-flex;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}

.navigation-links3-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: white;
  border-radius: var(--dl-radius-radius-radius2);
}

.navigation-links3-dropdown-toggle01 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.navigation-links3-dropdown-toggle01:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.navigation-links3-image {
  fill: #dd4f00;
  width: 2.4rem;
  height: 2.4rem;
  align-self: center;
  box-shadow: .025em .05em .125em rgba(0, 0, 0, 0.34);
  margin-left: 0.05rem;
  margin-right: .75rem;
  border-radius: .62rem;
}

.navigation-links3-recover {
  width: 100%;
  height: 100%;
}

.navigation-links3-dropdown01 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: white;
  border-radius: var(--dl-radius-radius-radius2);
}

.navigation-links3-dropdown-toggle02 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.navigation-links3-dropdown-toggle02:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.navigation-links3-image1 {
  fill: #dd4f00;
  width: 2.4rem;
  height: 2.4rem;
  align-self: center;
  box-shadow: .025em .05em .125em rgba(0, 0, 0, 0.34);
  margin-left: 0.05rem;
  margin-right: .75rem;
  border-radius: .62rem;
}

.navigation-links3-when-the-wind-blows {
  width: 100%;
  height: 100%;
}

.navigation-links3-dropdown02 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: white;
  border-radius: var(--dl-radius-radius-radius2);
}

.navigation-links3-dropdown-toggle03 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.navigation-links3-dropdown-toggle03:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.navigation-links3-image2 {
  fill: #dd4f00;
  width: 2.4rem;
  height: 2.4rem;
  align-self: center;
  box-shadow: .025em .05em .125em rgba(0, 0, 0, 0.34);
  margin-left: 0.05rem;
  margin-right: .75rem;
  border-radius: .62rem;
}

.navigation-links3-mom {
  width: 100%;
  height: 100%;
}

.navigation-links3-dropdown03 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: white;
  border-radius: var(--dl-radius-radius-radius2);
}

.navigation-links3-dropdown-toggle04 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.navigation-links3-dropdown-toggle04:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.navigation-links3-year-3 {
  width: 100%;
  height: 100%;
}

.navigation-links3-dropdown04 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: white;
  border-radius: var(--dl-radius-radius-radius2);
}

.navigation-links3-dropdown-toggle05 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.navigation-links3-dropdown-toggle05:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.navigation-links3-never-mine {
  width: 100%;
  height: 100%;
}

.navigation-links3-dropdown05 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: white;
  border-radius: var(--dl-radius-radius-radius2);
}

.navigation-links3-dropdown-toggle06 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.navigation-links3-dropdown-toggle06:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.navigation-links3-days-to-grow {
  width: 100%;
  height: 100%;
}

.navigation-links3-dropdown06 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: white;
  border-radius: var(--dl-radius-radius-radius2);
}

.navigation-links3-dropdown-toggle07 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.navigation-links3-dropdown-toggle07:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.navigation-links3-loner {
  width: 100%;
  height: 100%;
}

.navigation-links3-dropdown07 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: white;
  border-radius: var(--dl-radius-radius-radius2);
}

.navigation-links3-dropdown-toggle08 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.navigation-links3-dropdown-toggle08:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.navigation-links3-image3 {
  fill: #dd4f00;
  width: 2.4rem;
  height: 2.4rem;
  align-self: center;
  box-shadow: .025em .05em .125em rgba(0, 0, 0, 0.34);
  margin-left: 0.05rem;
  margin-right: .75rem;
  border-radius: .62rem;
}

.navigation-links3-icon2 {
  fill: var(--dl-color-gray-white);
  width: 2rem;
  filter: drop-shadow(.025em .05em .125em rgba(0, 0, 0, 0.34));
  height: 2rem;
  position: absolute;
  align-self: center;
  margin-left: .25em;
}

.navigation-links3-weird-state {
  width: 100%;
  height: 100%;
  padding-left: .25rem;
}

.navigation-links3-dropdown08 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: white;
  border-radius: var(--dl-radius-radius-radius2);
}

.navigation-links3-dropdown-toggle09 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.navigation-links3-dropdown-toggle09:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.navigation-links3-icon4 {
  fill: #dd4f00;
  width: 2rem;
  height: 2rem;
  align-self: center;
}

.navigation-links3-trance-being {
  width: 100%;
  height: 100%;
  padding-left: .25rem;
}

.navigation-links3-dropdown09 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: white;
  border-radius: var(--dl-radius-radius-radius2);
}

.navigation-links3-dropdown-toggle10 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.navigation-links3-dropdown-toggle10:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.navigation-links3-dropdown-toggle11 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.navigation-links3-dropdown-toggle11:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.navigation-links3-icon6 {
  fill: #dd4f00;
  width: 2rem;
  height: 2rem;
  align-self: center;
}

.navigation-links3-star {
  width: 100%;
  height: 100%;
  padding-left: .25rem;
}

.navigation-links3-link {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}

.navigation-links3-navlink1 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}

.navigation-links3-link1 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}












@media(max-width: 1600px) {
  .navigation-links3-dropdown-list {
    display: inline-flex;
    visibility: hidden;
    opacity: 0;
  }

  .navigation-links3-root-class-name15 {
    align-self: center;
  }
}

@media(max-width: 1200px) {
  .navigation-links3-dropdown-toggle {
    flex-direction: row;
  }

  .navigation-links3-text {
    color: rgb(0, 0, 0);
    font-style: normal;
    text-align: center;
    font-weight: 400;
  }
}

@media(max-width: 767px) {
  .navigation-links3-nav {
    align-items: flex-start;
    flex-direction: column;
  }

  .navigation-links3-navlink {
    margin-bottom: var(--dl-space-space-unit);
  }

  .navigation-links3-link {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }

  .navigation-links3-navlink1 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }

  .navigation-links3-link1 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
}

.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  background: #92a2e8;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
}

.home-header {
  width: 100%;
  /*display: flex;*/
  display: none;
  z-index: 3;
  position: fixed;
  background: white;
  color: var(--basecolor);
  transition: color 1180ms ease-in-out, background-color 1180ms ease-in-out;
  max-height: 6rem;
  min-height: 3.2rem;
  align-items: center;
  padding-top: 0.4rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  padding-bottom: .5rem;
  justify-content: space-between;
}

.icon {
  fill: var(--basecolor);
  width: 3rem !important;
  height: 3rem !important;
  z-index: 6;
  display: inline;
  box-sizing: content-box;
}

.home-image {
  height: 3.2rem;
  align-self: center;
  object-fit: cover;
  /*width:auto;*/
  /*min-width:9.6rem;*/
}

.home-nav {
  display: flex;
}

.home-icon-group {
  display: inline-block;
  position: relative;
  min-width: 6rem;
  align-self: center;
  min-height: 1.8rem;
  margin-left: -1rem;
  margin-right: 2rem;
}

.home-row {
  fill: #ff7700;
  display: inline-block;
}

.home-icon {
  fill: #ff7700;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;
  text-decoration: none;
}

.home-row1 {
  fill: #833AB4;
  display: inline-block;
}

.home-icon02 {
  fill: #D25D6F;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;
  text-decoration: none;
}

.home-row2 {
  fill: #FF0000;
  display: inline-block;
}

.home-icon04 {
  fill: #FF0000;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;
  text-decoration: none;
}

.home-burger-menu {
  display: none;
}

.home-icon06 {
  width: 2rem;
  height: 2rem;
  fill: var(--basecolor);
  transition: fill 1180ms ease-in-out;
}

.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: var(--dl-size-size-maxwidth);
  display: none;
  padding: 1.6rem;
  z-index: 100;
  position: absolute;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.home-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}

.home-image1 {
  height: 3.2rem;
}

.home-close-mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-icon08 {
  fill: var(--basecolor);
  width: 2rem;
  height: 2rem;
}

.home-nav2 {
  flex: 0 0 auto;
  width: 60%;
  display: flex;
  font-size: 1.6rem;
  align-items: center;
  padding-left: 4rem;
  padding-right: 7rem;
  flex-direction: row;
}

.home-container02 {
  flex: 0 0 auto;
  display: flex;
  font-size: 1.6rem;
  align-items: flex-start;
  flex-direction: column;
}

.home-text {
  color: var(--linkcolor);
}

.home-container03 {
  flex: 0 0 auto;
  display: flex;
  font-size: 1.6rem;
  align-items: flex-start;
  padding-left: 20%;
  flex-direction: column;
  padding-bottom: 2rem;
}

.home-thq-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}

.home-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  visibility: visible;
  opacity: 1;
  align-items: center;
  padding-top: .8rem;
  border-radius: var(--dl-radius-radius-radius2);
  padding-bottom: .8rem;
  justify-content: center;
}

.home-text1 {
  color: var(--basecolor) !important;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  vertical-align: middle;
}

.home-dropdown-arrow {
  transition: 0.3s;
}

.home-icon10 {
  fill: var(--basecolor);
  width: 3.2rem;
  height: 3.2rem;
  margin-top: auto;
  transition: 0.3s;
  margin-bottom: auto;
}

.home-dropdown-list {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  font-size: 1rem;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}

.home-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: white;
  border-radius: var(--dl-radius-radius-radius2);
}

.home-dropdown-toggle01 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.home-dropdown-toggle01:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.home-recover {
  width: 100%;
  height: 100%;
}

.home-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: white;
  border-radius: var(--dl-radius-radius-radius2);
}

.home-dropdown-toggle02 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.home-dropdown-toggle02:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.home-when-the-wind-blows {
  width: 100%;
  height: 100%;
}

.home-dropdown2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: white;
  border-radius: var(--dl-radius-radius-radius2);
}

.home-dropdown-toggle03 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.home-dropdown-toggle03:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.home-mom {
  width: 100%;
  height: 100%;
}

.home-dropdown3 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: white;
  border-radius: var(--dl-radius-radius-radius2);
}

.home-dropdown-toggle04 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.home-dropdown-toggle04:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.home-year-3 {
  width: 100%;
  height: 100%;
}

.home-dropdown4 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: white;
  border-radius: var(--dl-radius-radius-radius2);
}

.home-dropdown-toggle05 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.home-dropdown-toggle05:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.home-never-mine {
  width: 100%;
  height: 100%;
}

.home-dropdown5 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: white;
  border-radius: var(--dl-radius-radius-radius2);
}

.home-dropdown-toggle06 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.home-dropdown-toggle06:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.home-days-to-grow {
  width: 100%;
  height: 100%;
}

.home-dropdown6 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: white;
  border-radius: var(--dl-radius-radius-radius2);
}

.home-dropdown-toggle07 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.home-dropdown-toggle07:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.home-loner {
  width: 100%;
  height: 100%;
}

.home-dropdown7 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: white;
  border-radius: var(--dl-radius-radius-radius2);
}

.home-dropdown-toggle08 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.home-dropdown-toggle08:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.home-icon12 {
  fill: #dd4f00;
  width: 2rem;
  height: 2rem;
  align-self: center;
  visibility: visible;
  opacity: 1;
}

.home-weird-state {
  width: 100%;
  height: 100%;
  padding-left: .25rem;
}

.home-dropdown8 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: white;
  border-radius: var(--dl-radius-radius-radius2);
}

.home-dropdown-toggle09 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.home-dropdown-toggle09:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}

.home-icon14 {
  fill: #dd4f00;
  width: 2rem;
  height: 2rem;
  align-self: center;
}

.home-trance-being {
  width: 100%;
  height: 100%;
  padding-left: .25rem;
}

.home-container04 {
  flex: 0 0 auto;
  display: flex;
  font-size: 1.6rem;
  align-items: flex-start;
  padding-left: 20%;
  flex-direction: column;
  padding-bottom: 3rem;
}

.home-link3 {
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
}

.home-container05 {
  flex: 0 0 auto;
  display: flex;
  font-size: 1.6rem;
  align-items: flex-start;
  padding-left: 20%;
  flex-direction: column;
  padding-bottom: 2rem;
}

.home-navlink {
  text-decoration: none;
}

.home-container06 {
  flex: 0 0 auto;
  display: flex;
  font-size: 1.6rem;
  align-items: flex-start;
  padding-left: 20%;
  flex-direction: column;
}

.home-link4 {
  text-decoration: none;
}

.home-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 40vh;
  align-items: flex-start;
}

.home-container08 {
  fill: #FF0000;
}

.home-icon16 {
  fill: #ff7700;
  width: 3.2rem;
  height: 3.2rem;
  margin-right: 1rem;
  text-decoration: none;
}

.home-container09 {
  fill: #833AB4;
}

.home-icon18 {
  fill: #D25D6F;
  width: 3.2rem;
  height: 3.2rem;
  margin-right: 1rem;
  text-decoration: none;
}

.home-icon20 {
  width: 3.2rem;
  height: 3.2rem;
  margin-right: 1rem;
  text-decoration: none;
}

.home-hero {
  width: 100%;
  margin: 4rem 2rem;
  display: flex;
  padding: 2.4rem;
  position: relative;
  min-height: 57vh;
  height: auto;
  align-items: center;
  flex-direction: column;
  background-size: 100% 114vh, cover;
  /*background-size: cover;*/
  justify-content: center;
  background-color: #000;
  background-repeat: none;
  background-position: center;
  /*background-position: 25% 60%;*/
}

.home-container11 {
  width: 100%;
  /*height: 16em;*/
  display: flex;
  max-width: 100%;
  align-items: center;
  justify-content: center;
}

.home-image2 {
  width: 14.4rem;
  margin: 3rem;
  box-shadow: .25em .5em 3.5em rgba(0, 0, 0, 0.6);
  object-fit: cover;
}

.home-image3 {
  width: 14.4rem;
  box-shadow: .25em .5em 3.5em rgba(0, 0, 0, 0.6);
  object-fit: cover;
}

.home-image4 {
  width: 14.4rem;
  margin: 3rem;
  box-shadow: .25em .5em 3.5em rgba(0, 0, 0, 0.6);
  object-fit: cover;
}

.home-text2 {
  color: var(--dl-color-gray-white);
  font-size: 1.6rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 500;
  text-shadow: 0 .2rem .15rem rgba(0, 0, 0, 0.9), 0 .4rem .65rem rgba(0, 0, 0, 0.7), 0 .9rem 1.15rem rgba(0, 0, 0, 0.7);
  margin-bottom: var(--dl-space-space-twounits);
}

.home-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.home-link8 {
  width: auto;
  height: 6rem;
  min-height: 2rem;
  transition: 0.3s;
  bottom: 0;
  position: relative;
  padding-top: 1.875rem;
  border-width: 0px;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  padding-bottom: 2.25rem;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 1.4rem;
  background: #232323;
  box-sizing: box-border;
  box-shadow: .25em .5em 3.5em rgba(255, 255, 255, 0.6);
  animation: 4s glow infinite;
}

.home-link8:hover {
  transform: scale(1.02);
}

.home-text5 {
  font-size: 1.875rem !important;
  font-weight: 600;
  color: #FFF;
  box-sizing: box-content;
  position: relative;
  display: inline-block;
}

.home-iframe {
  width: 42rem;
  height: 35.2rem;
  max-width: 100%;
  margin-top: 1rem;
}

@media(max-width: 1600px) {
  .home-icon06 {
    width: 2rem;
    height: 2rem;
  }

  .home-text2 {
    color: var(--dl-color-gray-white);
    font-size: 1.6rem;
    font-weight: 500;
  }

  .home-link8 {
    font-size: 0.75rem;
    text-transform: uppercase;
  }

  .home-text5 {
    font-size: 1.6rem;
    text-align: center;
  }

  .home-iframe {
    width: 32rem;
    max-height: 100%;
    min-height: 100%;
    margin-left: 0px;
  }
}

@media(max-width: 1200px) {
  .home-dropdown-toggle {
    flex-direction: row;
  }

  .home-text1 {
    color: rgb(0, 0, 0);
    font-style: normal;
    text-align: center;
    font-weight: 400;
  }

  .home-text2 {
    color: var(--dl-color-gray-white);
  }

  .home-iframe {
    width: 32rem;
    max-width: auto;
    min-height: 100%;
    padding-top: 0px;
    padding-right: 0px;
  }
}

@media(max-width: 991px) {
  .home-icon-group {
    margin-right: 1rem;
  }

  .home-icon06 {
    display: flex;
  }

  .home-hero {
    flex-direction: column;
  }

  .home-text2 {
    text-align: center;
  }

  .home-iframe {
    width: 32rem;
    max-width: auto;
    min-height: 100%;
  }
}

@media(max-width: 767px) {
  .home-image {
    height: 2rem;
  }

  .home-header {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .home-nav {
    display: none;
  }

  .home-icon-group {
    align-self: center;
    margin-right: -4rem;
    padding-right: 6rem;
  }

  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home-icon06 {
    align-self: center;
    justify-content: center;
  }

  .home-nav2 {
    align-items: flex-start;
    flex-direction: column;

  }

  .home-text {
    margin-bottom: 1rem;
  }

  .home-link3 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }

  .home-navlink {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }

  .home-link4 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }

  .home-hero {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  .home-image2 {
    width: 25vw;
    margin: 1rem;
  }

  .home-image3 {
    width: 25vw;
    margin: 1rem;
  }

  .home-image4 {
    width: 25vw;
    margin: 1rem;
  }

  .home-iframe {
    width: 28rem;
    max-width: auto;
    max-height: 100%;
  }
}

@media(max-width: 479px) {
  .home-header {
    padding: 1rem;
  }

  .home-icon-group {
    display: none;
  }

  .home-mobile-menu {
    padding: 1rem;
  }

  .home-hero {
    padding: 2rem;
    padding-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .home-image2 {
    width: 30vw;
    margin: .5rem;
  }

  .home-image3 {
    width: 30vw;
    margin: .5rem;
  }

  .home-image4 {
    width: 30vw;
    margin: .5rem;
  }

  .home-btn-group {
    flex-direction: column;
  }

  .home-iframe {
    max-width: 100vw;
    min-width: 100%;
    align-self: stretch;
    margin-right: 0px;
    margin-bottom: 0px;
    padding-bottom: var(--dl-space-space-fiveunits);
  }
}